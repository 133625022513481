
.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: hsl(0deg 0% 75.85%);;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100000000;
    transition: .3s;
    background-color: #b8b8b7 s;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 50%);
}


.nav_logo{
    width: 35px;
    text-align: center;
    padding: 0 0 10px 0;
}

.nav_list{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.aside .nav_link{
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: .3s;
    padding-left: 4px;
}

nav ul li.active a{
    color: hsl(43, 100%, 68%);;
}

.nav_link:hover{
    color: hsl(43, 100%, 68%);
}

.nav_link:active{
    color: hsl(43, 100%, 68%);
} 

.nav_menu .nav_list li a{
    color: black;
    transition: 0.5s ease;
}

.nav_menu .nav_list li .active{
    color: hsl(43, 88%, 71%);
    
}

.nav_menu .nav_list li :hover{
    color: hsl(43, 88%, 71%);
}

.copyright{
    color: black;
    font-size: var(--small-font-size);
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
}

.nav_toggle{
    position: fixed;
    top:1.25rem;
    left: 1.875rem;
    cursor: pointer;
    height: 40px;
    width: 45px;
    background-color: var(--body-color);
    border: 1px solid #e8dfec;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100000000000000000 ;
    transition: .3s;
}

@media screen and (max-width:1024px){
    .aside{
      left: -110px;
    }

    .nav_toggle{
        display: flex;
    }

    .nav_toggle-open{
        left: 140px;
    }

    .show-menu{
        left: 0;
        height: 100%;
    }
  }
